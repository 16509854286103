const fetch = require('isomorphic-fetch');
export let subscriptions
let catalog

export function getCatalog() {
  if (catalog) return Promise.resolve(catalog)

  return fetchCatalog()
  .then((data) => {
    return Promise.all(data)
  })
  .then(data => {
    catalog = data;
    return catalog;
  })
}

export function getApi(apiId) {
  return getCatalog()
  .then(() => {
    let apis = catalog.filter((item) => item.id === apiId)

    return apis[0];
  })
}

export function fetchCatalog() {
  const bucketName = `janus-api-resources-${process.env.REACT_APP_PORTAL_ENV}`;
  const url = `https://s3-us-west-2.amazonaws.com/${bucketName}/api.json`
  return fetch(url)
    .then(function(response) {
      return response.json()
    }).then(function(json) {
      return json;
    }).catch(function(ex) {
      console.log('parsing failed', ex)
    });
}
