import React, { PureComponent } from 'react'
import { getApi } from '../../services/api-catalog'
import SwaggerUI from "swagger-ui-react"

const buildUrl = endpoint => {
  const bucketName = `janus-api-resources-${process.env.REACT_APP_PORTAL_ENV}`;
  const url = `https://s3-us-west-2.amazonaws.com/${bucketName}/${endpoint}`;
  return url;
};
export default class ApiDetailsPage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}

    getApi(props.match.params.apiId)
    .then(api => {
      this.setState({
        api
      })
    })
  }

  render() {
    if (this.state.api) {
    const url = buildUrl(this.state.api.swaggerUrl)
    return (<SwaggerUI url={url} />)
    }
    return (<div>Loading</div>)
  }
}
