import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from '../../pages/Home'
import GettingStarted from '../../pages/GettingStarted'
import Apis from '../../pages/Apis'
import ApiDetails from '../../pages/ApiDetails'
import AlertPopup from '../../components/AlertPopup'
import Header from '../../components/Header';
import './App.css'
import "swagger-ui-react/swagger-ui.css"

const NoMatch = () => <h2>Page not found</h2>

const AppF = () => {
  return (
    <div className="App">
        <Header />
        <section className="App-intro">
            <AlertPopup />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/getting-started" component={GettingStarted} />
              <Route exact path="/apis" component={Apis}/>
              <Route path="/apis/:apiId" component={ApiDetails}/>
              <Route component={NoMatch}/>
            </Switch>
        </section>
      </div>
  )
}
const withBrowserRouter = (Component) => props => (
  <BrowserRouter >
    <Component {...props} />
  </BrowserRouter>
);
export default withBrowserRouter(AppF)