import React from 'react'
import {Link} from 'react-router-dom';
import { Menu } from 'semantic-ui-react'

// TODO: Update <Search> usage after its will be implemented

const MenuExampleAttached = () => {
  return (
    <div className=''>
      <Menu attached='top' inverted>
        <Menu.Item link><Link to="/">Home</Link></Menu.Item>
      </Menu>

    </div>
  )
}

export default MenuExampleAttached
