import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Card }  from 'semantic-ui-react'

const ApiCard = ({api}) => (
<Card key={api.id} style={{textAlign: 'center'}}>
  <Card.Content>
    <Card.Header><Link to={`apis/${api.id}`}>{api.title}</Link></Card.Header>
    <Card.Description>{api.description}</Card.Description>
  </Card.Content>

</Card>)

export default ({ catalog }) => (
  <Container>
  <Card.Group itemsPerRow={3} stackable doubling>
    {catalog
      .sort((first, second) =>
        first.title === second.title ? 0 :
        first.title < second.title ? -1 : 1
      )
      .map(api => ApiCard({api}))}
  </Card.Group>
  </Container>
)
