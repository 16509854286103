import React, { PureComponent } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import ApiCatalog from '../../components/ApiCatalog'
import { getCatalog } from '../../services/api-catalog'

export default class ApisPage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}

    getCatalog().then(catalog => {
      console.log(catalog)
      this.setState({ catalog })
    })

  
  }

  render() {
    return (<div>
      {this.state.catalog ? <ApiCatalog catalog={this.state.catalog} /> : (<Dimmer active>
        <Loader content='Loading' />
      </Dimmer>)}
    </div>)
  }
}
